<template>
  <div class="wrp">
    <div class="xy-tid">球彩AI服务协议</div>
    <div class="cont">
      <h3>一、服务协议的重要性</h3>
      <span>1.1 服务协议的核心内容</span>
      <p>
        本协议是您与慧意计算机之间关于使用其服务的正式法律文件。它详细规定了双方的权利和义务，包括但不限于服务的范围、帐号安全、个人信息保护、服务使用方式、服务变更与终止等关键内容。通过同意本协议，您表明您已充分了解并接受其中的所有条款。
      </p>
      <span>1.2 特别提示条款</span>
      <p>
        我们特别提醒您关注那些可能免除或限制慧意计算机责任的条款、对用户权利进行限制的条款，以及明确争议解决方式和司法管辖权的条款。这些条款对于维护双方权益、明确责任界限至关重要。我们可能会采用加粗、加下划线等方式进行特别提示，但您仍需全面、审慎地阅读并理解本协议的每一项条款。
      </p>
    </div>

    <div class="cont">
      <h3>二、帐号与密码安全</h3>
      <span>2.1 帐号注册与保管</span>
      <p>
        在使用慧意计算机服务时，您可能需要注册一个帐号。我们强烈建议您使用强密码，并定期更换密码，以确保帐号安全。同时，您应妥善保管您的帐号和密码，避免因保管不善导致的帐号被盗或密码失窃。因您个人原因导致的帐号安全问题，责任由您自行承担。
      </p>
      <span>2.2 帐号形式与关联</span>
      <p>慧意计算机的帐号可能包括数字、字母、手机号码、电子信箱等多种形式。在您注册某一形式的帐号时，我们可能附赠该帐号的另一表现形式。请您务必了解并遵守相关的帐号使用规则，确保帐号信息的准确性和安全性。</p>
    </div>

    <div class="cont">
      <h3>三、用户个人信息保护</h3>
      <span>3.1 信息收集与使用</span>
      <p>
        慧意计算机将按照本协议及《慧意计算机隐私政策》的规定收集、使用、储存和分享您的个人信息。我们承诺采取一切必要措施保护您的个人信息免受未经授权的访问、使用或披露。同时，我们不会将您的个人信息转移或披露给任何第三方，除非符合相关法律法规的要求或为了提供您所要求的服务。
      </p>
      <span>3.2 未成年人保护</span>
      <p>若您为未成年人，在使用慧意计算机服务前，应事先取得您的监护人的同意。我们非常重视对未成年人个人信息的保护，将严格按照相关法律法规的要求处理未成年人的个人信息。</p>
    </div>

    <div class="cont">
      <h3>四、服务使用方式</h3>
      <span>4.1 非商业性质使用</span>
      <p>
        本服务仅供您个人非商业性质使用。您不得将本服务用于任何商业目的或非法活动。
      </p>
      <span>4.2 禁止行为</span>
      <p>您不得使用任何第三方软件、插件、外挂等对本服务进行干扰、破坏或施加其他影响。同时，您应严格遵守慧意计算机发布的Robots协议等相关协议规则，确保您的使用行为符合所有相关要求。</p>
    </div>

    <div class="cont">
      <h3>五、服务变更与终止</h3>
      <span>5.1 服务变更</span>
      <p>
        慧意计算机有权基于经营策略的调整对服务内容进行变更。在变更服务时，我们将尽力通知您并确保您的权益不受损害。然而，在某些情况下，我们可能无法提前通知您服务变更的具体内容。因此，请您定期关注我们的服务公告和更新信息。
      </p>
      <span>5.2 服务终止</span>
      <p>
        在发生违反法律法规、本协议约定或出于安全原因等必要情形时，慧意计算机有权不经通知而中断或终止向您提供服务。同时，若您未按时足额支付服务费用（如适用），我们也有权中断、中止或终止提供服务。在服务终止后，我们将根据相关法律法规的要求处理您的个人信息和数据。
      </p>
    </div>

    <div class="cont">
      <h3>六、争议解决与司法管辖</h3>
      <span>6.1 争议解决方式</span>
      <p>
        若您与慧意计算机之间发生任何纠纷或争议，应首先通过友好协商解决。协商不成的，您可以按照本协议第十八条的约定将纠纷或争议提交至有管辖权的人民法院诉讼解决。
      </p>
      <span>6.2 司法管辖权</span>
      <p>
        本协议的签订地为中华人民共和国广东省深圳市南山区。因此，本协议的成立、生效、履行、解释及纠纷解决等相关事宜均适用中华人民共和国大陆地区法律（不包括冲突法）。在发生争议时，双方应遵守中华人民共和国的法律法规并接受有管辖权法院的管辖。
      </p>
    </div>

    <div class="cont">
      <h3>七、其他重要事项</h3>
      <span>7.1 未成年人使用条款</span>
      <p>
        若用户未满18周岁，应在监护人监护、指导下阅读本协议并使用本服务。监护人应加强对未成年人使用网络的引导和监督，确保未成年人的网络安全和健康成长。
      </p>
      <span>7.2 客户服务与支持</span>
      <p>
        如果您对本协议或本服务有任何疑问或建议，请随时联系我们的客户服务部门。我们将竭诚为您提供必要的帮助和支持。
      </p>
    </div>
  </div>

</template>
  
  <script>
export default {
  data() {
    return {
      Height: 0,
    };
  },
  created() {},

  methods: {},
};
</script>
  <style scoped>
.wrp {
  width: 130rem;
  margin: 10.5rem auto 0px;
  text-align: left;
  padding-bottom: 10rem;
}
.xy-tid {
  text-align: center;
  padding-top: 10rem;
  font-size: 3.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 8rem;
}
.cont {
  margin-top: 3rem;
}
.cont h3 {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.cont span {
  margin-left: 3.6rem;
  font-size: 1.6rem;
  font-weight: bold;
}
.cont p {
  margin-left: 6.2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  text-align: justify;
  text-indent: 2em;
  line-height: 2.5rem;
}

@media (max-width: 768px) {
  .header {
    display: none;
  }
  .footer {
    display: none;
  }
}
</style>